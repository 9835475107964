import Image from 'next/image';
import Link from 'next/link';
import React from 'react';

import { AppStoreBtn } from '@/assets';

export default function AppStore() {
  return (
    <Link href="https://testflight.apple.com/join/Lzy5HKls" target="_blank">
      <Image src={AppStoreBtn} alt="app_store_btn" className="h-16 w-auto" />
    </Link>
  );
}
