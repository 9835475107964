import { t } from 'i18next';
import moment from 'moment-timezone';

export const intParam = (value: string | null, defaultValue?: number) => {
  if (value === null) return defaultValue;
  return parseInt(value, 10);
};

export const strParam = (value: string | null, defaultValue?: string) => {
  if (value === null) return defaultValue;
  return value;
};

export const floatParam = (value: string | null, defaultValue?: number) => {
  if (value === null) return defaultValue;
  return parseFloat(value);
};

export const typeParam = <T extends string>(
  value: any,
  defaultValue?: T
): T | undefined => {
  if (value === null) return defaultValue;
  return value;
};

export const currency = (value: number | undefined) => {
  return (
    value !== undefined && `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
  );
};

export const addDays = (date: Date, days: number) => {
  const result = new Date(date);
  result.setDate(result.getDate() + days);
  return moment(result).format('DD/MM/YYYY');
};

export const getDateLabel = (date?: Date) => {
  const today = new Date();
  if (date == null) return 'Chọn ngày';

  const dateStr = moment(date).format('DD/MM/YYYY');

  if (dateStr === addDays(today, 0)) return 'Hôm nay';
  if (dateStr === addDays(today, -1)) return 'Hôm qua';
  if (dateStr === addDays(today, 1)) return 'Ngày mai';
  return dateStr;
};

export const getDateRangeLabel = (from?: Date, to?: Date) => {
  if (from == null || to == null) return 'Chọn ngày';
  return `${moment(from).format('DD/MM/YYYY')} - ${moment(to).format(
    'DD/MM/YYYY'
  )}`;
};

export const normalizePhoneNumber = (
  phoneNumber?: string,
  countryCode: string = '84'
) => {
  if (phoneNumber == null) return '';
  return phoneNumber.startsWith(`+${countryCode}`)
    ? phoneNumber.replace(`+${countryCode}`, '0')
    : phoneNumber;
};

export const parsePhoneNumber = (
  phoneNumber?: string,
  countryCode: string = '84'
) => {
  if (phoneNumber == null) return '';
  return phoneNumber.startsWith('0')
    ? phoneNumber.replace('0', `+${countryCode}`)
    : phoneNumber;
};

export const parseErrorMessage = (fieldName: string, errorMessage?: string) => {
  if (errorMessage == null) return undefined;
  return errorMessage.replaceAll(`'${fieldName}'`, t(`fields.${fieldName}`));
};
