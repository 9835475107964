import type { HTMLProps } from 'react';

interface TitleProps extends HTMLProps<HTMLDivElement> {
  tag?: string;
  title: string;
  description?: string;
}

export const Title = ({ tag, title, description, ...props }: TitleProps) => {
  return (
    <div {...props}>
      {tag && <div className="mb-3 font-semibold text-primary-500">{tag}</div>}
      <h2 className="text-4xl">{title}</h2>
      {description && (
        <p className="mt-6 text-xl text-gray-600">{description}</p>
      )}
    </div>
  );
};
