import { motion, useAnimation } from 'framer-motion';
import Image from 'next/image';
import { useEffect } from 'react';

import { HeroImage } from '@/assets';
import { HeroContent } from '@/components/headers/HeroContent';

export const HeroMain = () => {
  const controls = useAnimation();

  useEffect(() => {
    controls
      .start({
        opacity: 1,
        scale: 1,
        transition: {
          duration: 0.8,
          delay: 0.2,
          ease: [0, 0.71, 0.2, 1.01],
        },
      })
      .then(() => {
        controls.start({
          x: ['0px', '-16px', '16px', '12px', '-16px', '0px'],
          y: ['0px', '16px', '-16px', '8px', '-16px', '0px'],
          transition: {
            duration: 10,
            repeat: Infinity,
            ease: 'easeInOut',
          },
        });
      });
  }, [controls]);

  return (
    <div className="relative z-10 grid size-full grid-cols-1 flex-col items-center px-6 py-16 lg:grid-cols-2 lg:py-16 xl:justify-between">
      <HeroContent />
      <motion.div
        className="mt-10 aspect-square w-full p-10 lg:mt-0"
        initial={{ opacity: 0, scale: 0.5 }}
        animate={controls}
      >
        <div className="relative size-full">
          <Image
            src={HeroImage}
            alt="mockup image"
            className="object-contain drop-shadow-sm"
            fill
            unoptimized
          />
        </div>
      </motion.div>
    </div>
  );
};
