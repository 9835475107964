import AppStore from '../stores/AppStore';
import GooglePlay from '../stores/GooglePlay';

export const SolutionsSection = () => {
  return (
    <section
      id="solutions"
      className="m-auto flex flex-col items-center bg-gradient-to-b from-amber-200 to-cyan-200 px-2 py-20 text-center"
    >
      <h2 className="max-w-3xl text-3xl leading-tight sm:text-5xl">
        Cùng chúng tôi sử dụng các giải pháp chuyên nghiệp dành riêng cho cửa
        hàng của bạn
      </h2>
      <p className="mt-6 text-xl text-gray-600">
        Hãy trải nghiệm ứng dụng Hàng Rong ngay hôm nay
      </p>
      <div className="mt-8 flex gap-4">
        <AppStore />
        <GooglePlay />
      </div>
    </section>
  );
};
