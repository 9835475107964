import type { HTMLMotionProps } from 'framer-motion';
import { motion } from 'framer-motion';
import React from 'react';

export interface UtilityProps extends HTMLMotionProps<'div'> {
  icon: JSX.Element | React.ReactNode | any;
  title: string;
  description: string;
}

export const Utility = ({
  icon,
  title,
  description,
  ...props
}: UtilityProps) => {
  return (
    <motion.div {...props}>
      <div className="mb-2">{icon}</div>
      <h3 className="mb-2 text-xl">{title}</h3>
      <p className="text-gray-600">{description}</p>
    </motion.div>
  );
};
