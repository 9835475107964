import type { GetStaticProps } from 'next';
import { useRouter } from 'next/router';
import { useTranslation } from 'next-i18next';
import { serverSideTranslations } from 'next-i18next/serverSideTranslations';

import { FeaturesSection } from '@/components/sections/FeaturesSection';
import HeroSection from '@/components/sections/HeroSection';
import { PerformanceSection } from '@/components/sections/PerformanceSection';
import { PricingSection } from '@/components/sections/PricingSection';
import { SolutionsSection } from '@/components/sections/SolutionsSection';
import { TestimonialsSection } from '@/components/sections/TestimonialsSection';
import { UtilitiesSection } from '@/components/sections/UtilitiesSection';
import LandingPageTemplate from '@/templates/LandingPage';

export const getStaticProps = (async (context) => {
  return {
    props: {
      ...(await serverSideTranslations(context.locale || 'vi', ['common'])),
    },
    revalidate: 60,
  };
}) satisfies GetStaticProps<{}>;

const LandingPage = () => {
  const { t } = useTranslation('common');
  const { locale } = useRouter();

  return (
    <LandingPageTemplate
      seo={{
        title: t('seo.home.title').toString(),
        description: t('seo.home.description').toString(),
        canonical: process.env.NEXT_PUBLIC_BASE_URL,
        openGraph: {
          type: 'website',
          url: process.env.NEXT_PUBLIC_BASE_URL,
          title: t('seo.home.title').toString(),
          description: t('seo.home.description').toString(),
          locale,
          images: [
            {
              url: `${process.env.NEXT_PUBLIC_BASE_URL}assets/images/meta-image.jpg`,
              alt: 'Hangrong meta image alt',
            },
          ],
        },
      }}
    >
      <HeroSection />
      <div className="w-full">
        <div className="relative w-full">
          <UtilitiesSection />
          <PerformanceSection />
          <FeaturesSection />
          <SolutionsSection />
          <PricingSection />
          <TestimonialsSection />
        </div>
      </div>
    </LandingPageTemplate>
  );
};

export default LandingPage;
