import { Check } from '@untitled-ui/icons-react';
import { motion } from 'framer-motion';

const variants = {
  origin: {
    parent: 'lg:flex-row lg:ml-28',
    image:
      'group relative lg:bg-gradient-to-r lg:from-primary-300 lg:via-primary-200 lg:ml-2 overflow-hidden',
    variantsContent: {
      offscreen: { x: -300, opacity: 0 },
      onscreen: {
        opacity: 1,
        x: 0,
        transition: {
          type: 'spring',
          delay: 0.3,
          duration: 0.8,
        },
      },
    },
    variantsImage: {
      offscreen: { x: 300, opacity: 0 },
      onscreen: {
        opacity: 1,
        x: 0,
        transition: {
          type: 'spring',
          delay: 0.3,
          duration: 0.8,
        },
      },
    },
  },
  reverse: {
    parent: 'lg:flex-row-reverse lg:mr-28',
    image:
      'group relative lg:bg-gradient-to-l lg:from-primary-300 lg:via-primary-200 lg:mr-24 overflow-hidden',
    variantsContent: {
      offscreen: { x: 300, opacity: 0 },
      onscreen: {
        opacity: 1,
        x: 0,
        transition: {
          type: 'spring',
          delay: 0.3,
          duration: 0.8,
        },
      },
    },
    variantsImage: {
      offscreen: { x: -300, opacity: 0 },
      onscreen: {
        opacity: 1,
        x: 0,
        transition: {
          type: 'spring',
          delay: 0.3,
          duration: 0.8,
        },
      },
    },
  },
};

export interface FeatureText {
  item: string;
  sublist?: string[];
}
export interface FeatureProps {
  icon: JSX.Element | React.ReactElement;
  image: JSX.Element | React.ReactElement;
  title: string;
  description: string;
  features: FeatureText[];
  variant?: keyof typeof variants;
}

export const Feature = ({
  icon,
  image,
  title,
  description,
  features,
  variant = 'origin',
  ...props
}: FeatureProps) => {
  return (
    <div
      className={`flex flex-col items-center lg:items-start lg:justify-between ${variants[variant].parent} overflow-hidden even:mt-24`}
      {...props}
    >
      <motion.div
        className="flex-1 lg:max-w-[560px]"
        initial="offscreen"
        whileInView="onscreen"
        viewport={{ once: true }}
        variants={variants[variant].variantsContent}
      >
        <div className="mb-6">{icon}</div>
        <h3 className="mb-4 text-3xl">{title}</h3>
        <p className="mb-8 text-lg">{description}</p>
        {features.map((feature, key) => (
          <div key={key} className="flex">
            <div className="mr-2 w-fit">
              <Check className="rounded-full bg-primary-50 p-1 text-primary-500" />
            </div>
            <div className="text-gray-600">
              {feature.item}
              <ul className="my-5 list-inside list-disc">
                {feature.sublist?.map((item, k) => (
                  <li className="mb-1 ml-2" key={k}>
                    {item}
                  </li>
                ))}
              </ul>
            </div>
          </div>
        ))}
      </motion.div>
      <motion.div
        initial="offscreen"
        whileInView="onscreen"
        viewport={{ once: true }}
        className={`flex flex-1 items-center rounded-3xl py-16 lg:px-16 ${variants[variant].image} lg:max-w-[711px]`}
        variants={variants[variant].variantsImage}
      >
        <div
          className={`absolute inset-0 bg-primary-300 transition-transform duration-500 group-hover:translate-x-0 ${
            variant === 'origin' ? '-translate-x-full' : 'translate-x-full'
          }`}
        ></div>
        <div className="relative z-[1] transition-all duration-300 hover:scale-110">
          {image}
        </div>
      </motion.div>
    </div>
  );
};
