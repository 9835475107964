import 'swiper/css';
import 'swiper/css/pagination';

import { Pagination } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';

import {
  CustomerAvatar1,
  CustomerAvatar2,
  CustomerAvatar3,
  CustomerAvatar4,
} from '@/assets';

import type { CardTestimionialProps } from '../common/CardTestimionial';
import { CardTestimionial } from '../common/CardTestimionial';

const reviews: CardTestimionialProps[] = [
  {
    point: 5,
    review: `Tôi biết đến Hàng Rong từ những ngày đầu tiên làm quán. Hàng Rong đã giúp tôi tối ưu hơn quy trình phục vụ khách hàng, qua đó nhận được
    sự hài lòng và đánh giá cao từ các khách hàng.`,
    avatar: CustomerAvatar1,
    name: 'Nguyễn Bách Việt',
    role: 'Chủ quán ăn',
  },
  {
    point: 5,
    review: `Trước khi biết đến Hàng Rong tôi luôn phải đau đầu mỗi tuần với các hóa đơn giấy từ nhập hàng đến bán hàng. Khi biết đến hàng rong
    việc quản lý các khoản chi thu của cửa hàng trở nên trực quan và dễ dàng hơn, không phải dành nhiều thời gian để tổng hợp các hóa đơn giấy nữa.`,
    avatar: CustomerAvatar2,
    name: 'Thái Hoàng Thịnh',
    role: 'Chủ quán nước',
  },
  {
    point: 5,
    review: `Từ khi gặp được Hàng Rong từ một người không có nhiều kiến thức về công nghệ như tôi cũng có thể quản lý quán thông qua điện thoại, giúp tôi
    thuận lợi hơn trong việc quản lý khi không có mặt ở quán`,
    avatar: CustomerAvatar3,
    name: 'Nguyễn Lê Việt Hoàng',
    role: 'Chủ quán lẩu nướng',
  },
  {
    point: 5,
    review: `Hàng Rong thực sự là một đối tác đáng tin cậy cho quán ăn của chúng tôi. Tính năng quản lý thông minh và khả năng tương tác linh hoạt đã giúp
    chúng tôi tăng cường hiệu suất làm việc và cung cấp dịch vụ tốt nhất cho khách hàng của mình`,
    avatar: CustomerAvatar4,
    name: 'Nguyễn Minh Tuấn',
    role: 'Chủ quán nhậu',
  },
];

export const TestimonialsSection = () => {
  return (
    <div className="w-full bg-primary-300 px-6 py-40">
      <Swiper
        modules={[Pagination]}
        spaceBetween={50}
        slidesPerView={1}
        breakpoints={{
          640: {
            slidesPerView: 2,
          },
          1024: {
            slidesPerView: 3,
          },
        }}
        pagination={{
          el: '#containerForBullets',
          type: 'bullets',
          bulletClass: 'swiper-pagination-bullet',
          bulletActiveClass: 'swiper-pagination-bullet-active',
          clickable: true,
          renderBullet(_index, className) {
            return `<span class="${className} mx-2"></span>`;
          },
        }}
        scrollbar={{ draggable: true }}
        className="relative max-w-[1216px]"
      >
        {reviews.map((review, key) => (
          <SwiperSlide key={key}>
            <CardTestimionial {...review} />
          </SwiperSlide>
        ))}
        <div id="containerForBullets" className="mt-8 text-center"></div>
      </Swiper>
    </div>
  );
};
