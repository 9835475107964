export const basePlans: PricingPlan[] = [
  {
    hide_on_android: false,
    hide_on_ios: false,
    is_default: false,
    duration: 1,
    original_duration: 1,
    original_price: '99000',
    price: '99000',
    app_store_iap_id: '6593674036',
    code: 'baseplan1',
    desc: 'Sử dụng tất cả các tính năng quản lý cơ bản.',
    google_play_iap_id: 'baseplan1',
    name: 'Gói Base 1 tháng ',
    time_unit: 'M',
    created_at: '2024-03-02T19:58:50.803312+07:00',
    updated_at: '2024-07-31T14:58:54.536717+07:00',
    id: 'f4f539d3-9118-4711-ad47-e8a806f79b6a',
    service_plan_id: '757cb3fc-9171-4dd1-832e-26aafe08dbb9',
  },
  {
    hide_on_android: false,
    hide_on_ios: false,
    is_default: false,
    duration: 6,
    original_duration: 6,
    original_price: '599000',
    price: '549000',
    app_store_iap_id: '6593674311',
    code: 'baseplan6',
    desc: 'Sử dụng tất cả các tính năng quản lý cơ bản.',
    google_play_iap_id: 'baseplan6',
    name: 'Gói Base 6 tháng ',
    time_unit: 'M',
    created_at: '2024-03-02T19:59:13.18334+07:00',
    updated_at: '2024-07-31T15:04:52.002455+07:00',
    id: '660bcf5d-f727-4938-8c5c-3f1eba27ce95',
    service_plan_id: '757cb3fc-9171-4dd1-832e-26aafe08dbb9',
  },
  {
    hide_on_android: false,
    hide_on_ios: false,
    is_default: false,
    duration: 12,
    original_duration: 12,
    original_price: '1199000',
    price: '999000',
    app_store_iap_id: '6593674597',
    code: 'baseplan12',
    desc: 'Sử dụng tất cả các tính năng quản lý cơ bản.',
    google_play_iap_id: 'baseplan12',
    name: 'Gói Base 12 tháng ',
    time_unit: 'M',
    created_at: '2024-03-02T20:00:02.214909+07:00',
    updated_at: '2024-07-31T15:06:34.256476+07:00',
    id: 'f448b744-467b-427f-8b6d-8708832627f8',
    service_plan_id: '757cb3fc-9171-4dd1-832e-26aafe08dbb9',
  },
];

export const proPlans: PricingPlan[] = [
  {
    hide_on_android: false,
    hide_on_ios: false,
    is_default: false,
    duration: 1,
    original_duration: 1,
    original_price: '199000',
    price: '199000',
    app_store_iap_id: '6593674849',
    code: 'proplan1',
    desc: 'Sử dụng tất cả các tính năng quản lý cao cấp.',
    google_play_iap_id: 'proplan1',
    name: 'Gói Pro 1 tháng',
    time_unit: 'M',
    created_at: '2024-03-05T10:36:14.682374+07:00',
    updated_at: '2024-07-31T15:09:18.954117+07:00',
    id: '52fe8e46-d4a4-469f-b4d4-e5a3594e11a4',
    service_plan_id: 'aa565cf4-a3ff-4b85-852b-9f96e5ce95eb',
  },
  {
    hide_on_android: false,
    hide_on_ios: false,
    is_default: false,
    duration: 6,
    original_duration: 6,
    original_price: '1199000',
    price: '1099000',
    app_store_iap_id: '6593674977',
    code: 'proplan6',
    desc: 'Sử dụng tất cả các tính năng quản lý cao cấp.',
    google_play_iap_id: 'proplan6',
    name: 'Gói Pro 6 tháng',
    time_unit: 'M',
    created_at: '2024-03-05T10:36:51.757126+07:00',
    updated_at: '2024-07-31T15:12:01.264646+07:00',
    id: 'bb5c5301-c537-4530-93fb-b1e29faa0a86',
    service_plan_id: 'aa565cf4-a3ff-4b85-852b-9f96e5ce95eb',
  },
  {
    hide_on_android: false,
    hide_on_ios: false,
    is_default: false,
    duration: 12,
    original_duration: 12,
    original_price: '2399000',
    price: '1999000',
    app_store_iap_id: '6593675011',
    code: 'proplan12',
    desc: 'Sử dụng tất cả các tính năng quản lý cao cấp.',
    google_play_iap_id: 'proplan12',
    name: 'Gói Pro 12 tháng',
    time_unit: 'M',
    created_at: '2024-03-05T10:37:13.195613+07:00',
    updated_at: '2024-07-31T15:13:34.320663+07:00',
    id: '6a276a1e-6efb-42b8-8e18-284f4326bc43',
    service_plan_id: 'aa565cf4-a3ff-4b85-852b-9f96e5ce95eb',
  },
];

export interface PricingPlan {
  hide_on_android: boolean;
  hide_on_ios: boolean;
  is_default: boolean;
  duration: number;
  original_duration: number;
  original_price: string;
  price: string;
  app_store_iap_id: string;
  code: string;
  desc: string;
  google_play_iap_id: string;
  name: string;
  time_unit: string;
  created_at: string;
  updated_at: string;
  id: string;
  service_plan_id: string;
}
