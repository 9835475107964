import {
  animate,
  motion,
  useInView,
  useMotionTemplate,
  useMotionValue,
  useTransform,
} from 'framer-motion';
import { useEffect, useRef } from 'react';

import { Title } from '@/components/common/Title';

interface CounterProps {
  percent: number;
  title: string;
}

const performanceData: CounterProps[] = [
  {
    percent: 80,
    title: 'Tiết kiệm thời gian',
  },
  {
    percent: 90,
    title: 'Tăng năng suất làm việc',
  },
  {
    percent: 90,
    title: 'Tự động hóa',
  },
  {
    percent: 100,
    title: 'Chính xác',
  },
];

const Counter = ({ percent, title }: CounterProps) => {
  const ref = useRef(null);
  const isInView = useInView(ref, { once: true });
  const count = useMotionValue(1);
  const value = useTransform(count, (end) => Math.round(end));
  useEffect(() => {
    if (isInView) {
      animate(count, percent, { duration: 1 });
    }
  }, [isInView]);
  return (
    <div ref={ref} className="px-2 py-5 text-center lg:py-0">
      <motion.div className="text-4xl font-semibold text-primary-500 sm:text-5xl">
        {useMotionTemplate`${value}%`}
      </motion.div>
      <div className="text-lg font-semibold">{title}</div>
    </div>
  );
};

export const PerformanceSection = () => {
  return (
    <section
      id="perfomance"
      className="m-auto mt-48 px-6 lg:container lg:px-28"
    >
      <Title
        title="Tối ưu quy trình, nâng cao hiệu suất ngay với Hàng Rong"
        className="m-auto max-w-3xl text-center"
      />
      <div className="mt-16 flex flex-col rounded-2xl bg-gray-50 px-6 py-16 sm:mx-24 lg:mx-0 lg:flex-row lg:flex-wrap lg:justify-between lg:px-12">
        {performanceData.map((data, key) => (
          <Counter {...data} key={key} />
        ))}
      </div>
    </section>
  );
};
